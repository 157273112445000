<section class="page-area">

  <div class="padded-content top-bottom-padding">
    <app-content-header *ngIf="!apps?.length" title="No data"></app-content-header>

    <ng-container *ngIf="!apps?.length && !loading">
      <p>You currently have no apps configured, or no recent data available for your apps. Please contact your account manager or email us at <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.
      </p>
      <br />
      <a class="btn btn-primary" href="/" onclick="localStorage.removeItem('app.analysis.settings');">Return to the dashboard</a>
    </ng-container>

    <app-unconfigured-networks *ngIf="unconfiguredNetworkIds?.length" [missingNetworkIdsByApp]="unconfiguredNetworkIds" showApps="true"></app-unconfigured-networks>


    <table *ngIf="apps?.length" class="key-stats with-trend-lines">
      <ng-container *ngFor="let app of apps; let i = index" class="index-entry">

        <tr class="header">
          <td [colSpan]="(metricColumnCount * 2) + (showNetworksBreakdown ? 1 : 0) + (showRegionsBreakdown ? 1 : 0)">
            <header>
              <a [routerLink]="['analysis', 'apps', app.id]">
                <i 
                  [class.fa-solid]="isWeb(app.appDetails)" 
                  [class.fab]="!isWeb(app.appDetails)"  
                  class="title-icon fa-{{app.appDetails?.operatingSystem | osFontIcon | lowercase}}">
                </i>
                <h3>{{app.appDetails?.displayName}}</h3></a>
              <span class="spacer"></span>
              <app-analysis-settings *ngIf="i === 0"></app-analysis-settings>
              <app-dashboard-page-settings [appId]="app.id"></app-dashboard-page-settings>
            </header>
          </td>
        </tr>

        <tr>
          <td class="icon bottom-left" rowspan="3"><a [routerLink]="['analysis', 'apps', app.id]">
            <img alt="{{app.appDetails?.displayName}}" src="{{app.appDetails | appIconUrlLarge}}" /></a>
          </td>
          <td *ngIf="showNetworksBreakdown" class="donut chart" rowspan="3">
            <div>
              <highcharts-chart *ngIf="app.charts?.networks" [Highcharts]="Highcharts" [options]="app.charts?.networks" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
            </div>
          </td>
          <td *ngIf="showRegionsBreakdown" class="donut chart" rowspan="3">
            <div>
              <highcharts-chart *ngIf="app.charts?.regions" [Highcharts]="Highcharts" [options]="app.charts?.regions" oneToOne="true" runOutsideAngular="true"></highcharts-chart>
            </div>
          </td>
          <ng-container *ngFor="let metric of app.analysis?.metrics || []; let i = index">
            <td [class.last]="i === metricColumnCount - 1" class="title"><span [tooltip]="metric.config.description" class="nowrap" container="body"
                    delay="500">{{metric.config.shortDisplayName || metric.config.displayName}}</span></td>
            <td *ngIf="i !== metricColumnCount - 1" class="divider" rowspan="3"><span></span></td>
          </ng-container>
          <ng-container *ngFor="let _ of paddingColumns(app); let i = index">
            <td [class.last]="i + app.analysis.metrics.length === metricColumnCount - 1" class="title"><span></span></td>
            <td *ngIf="i + app.analysis.metrics.length !== metricColumnCount - 1" class="divider" rowspan="3"><span></span></td>
          </ng-container>
        </tr>

        <tr>
          <td *ngFor="let metric of app.analysis?.metrics || []; let i = index" [class.last]="i === metricColumnCount - 1" [goodBadClass]="metric?.percentageChange"
                  [negativeIsGood]="metric.config.negativeIsGood" [neutral]="metric.config.neutral" class="key-stat-value">
            <div>
              <app-metric-current-value *ngIf="metric?.current" [config]="metric.config" [value]="metric?.current"></app-metric-current-value>
              <span *ngIf="!metric?.current">-</span>
              <span *ngIf="metric?.current" [innerHTML]="metric?.percentageChange | percentageChange:'1.0-0':metric.config.negativeIsGood:metric.config.neutral"></span>
            </div>
          </td>
          <td *ngFor="let _ of paddingColumns(app); let i = index" [class.last]="i + app.analysis.metrics.length === metricColumnCount - 1" class="key-stat-value">
            <div><span></span></div>
          </td>
        </tr>

        <tr>
          <td *ngFor="let metric of app.analysis?.metrics || []" [class.bottom-right]="i === metricColumnCount - 1" [class.last]="i === metricColumnCount - 1" class="mini-trend-line">
            <highcharts-chart *ngIf="app.charts?.metrics[metric.config.name]" [Highcharts]="Highcharts" [options]="app.charts?.metrics[metric.config.name]" oneToOne="true"
                    runOutsideAngular="true"></highcharts-chart>
            <!-- Force correct spacing if trend line charts are absent -->
            <div *ngIf="!app.charts?.metrics[metric.config.name]" class="placeholder"></div>
          </td>

          <td *ngFor="let _ of paddingColumns(app); let i = index"
                  [class.bottom-right]="i + app.analysis.metrics.length === metricColumnCount - 1"
                  [class.last]="i + app.analysis.metrics.length === metricColumnCount - 1" class="mini-trend-line">
            <div class="placeholder"></div>
          </td>
        </tr>

        <tr *ngIf="i < apps.length - 1" class="spacer">
          <td [colSpan]="(metricColumnCount * 2) + (showNetworksBreakdown ? 1 : 0) + (showRegionsBreakdown ? 1 : 0)"></td>
        </tr>
      </ng-container>
    </table>
  </div>
</section>
