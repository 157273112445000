export enum ApplicationPlatform {
  iOS,
  Android,
  Web
}

export const ApplicationPlatformDescription = [
  'iOS',
  'Android',
  'Web'
];
