import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationPlatform } from 'src/app/api-model/enums/mobile-operating-system';

@Pipe({
  name: 'osName'
})
export class OsNamePipe implements PipeTransform {

  public transform(value: ApplicationPlatform): string {
    switch (value) {
      case ApplicationPlatform.Android:
        return 'Android';
      case ApplicationPlatform.iOS:
        return 'iOS';
      case ApplicationPlatform.Web:
        return 'Web';
    }

    return '';
  }

}
