import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationPlatform } from 'src/app/api-model/enums/mobile-operating-system';

@Pipe({
  name: 'osIcon'
})
export class OsIconPipe implements PipeTransform {

  public transform(value: ApplicationPlatform): string {
    switch (value) {
      case ApplicationPlatform.Android:
        return `/assets/images/os-icon-android.svg`;
      case ApplicationPlatform.iOS:
        return `/assets/images/os-icon-ios.svg`;
      case ApplicationPlatform.Web:
        return `/assets/images/os-icon-web.svg`;
    }

    return '';
  }

}
