import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationPlatform } from 'src/app/api-model/enums/mobile-operating-system';

@Pipe({
  name: 'osFontIcon'
})
export class OsFontIconPipe implements PipeTransform {

  public transform(value: ApplicationPlatform): string {
    switch (value) {
      case ApplicationPlatform.Android:
        return 'android';
      case ApplicationPlatform.iOS:
        return 'apple';
      case ApplicationPlatform.Web:
        return 'globe';
    }

    return '';
  }

}
