// noinspection JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { ApplicationPlatform } from '../../api-model/enums/mobile-operating-system';

@Injectable({
  providedIn: 'root'
})
export class EnumHelperService {

  public getDescriptionMap(type: any, descriptions: string[]) {
    return Object.values(type).filter(x => typeof x === 'number').map((x: number) => {
      return {
        label: descriptions[x],
        value: x
      };
    });
  }

  public getNames(type: any): string[] {
    return Object.values(type).filter(x => typeof x === 'string') as string[];
  }

  public getValue<T>(type: any, label: string): ApplicationPlatform {
    return (Object.entries(type).find(x => x[0].toLowerCase() === label) ?? [null, null])[1] as ApplicationPlatform;
  }

  public getLabel(type: any, value: number): string {
    return (Object.entries(type).find(x => x[0].toLowerCase() === value?.toString().toLowerCase()) ?? [null, null])[1] as string;
  }

}
