import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { ApplicationPlatform } from '../../api-model/enums/mobile-operating-system';
import { OsNamePipe } from './os-name.pipe';

@Injectable()
@Pipe({ name: 'appIconUrlSmall' })
export class AppIconUrlSmallPipe implements PipeTransform {

  public constructor(private readonly osName: OsNamePipe) {}

  public transform(app: { iconUrlSmall?: string; operatingSystem?: ApplicationPlatform }): string {
    if (app?.iconUrlSmall) { return app.iconUrlSmall; }
    return app?.iconUrlSmall
      ? app?.iconUrlSmall
      : `/assets/images/default-app-icon${!isNil(app?.operatingSystem) ? '-' : ''}${this.osName.transform(app?.operatingSystem).toLowerCase()}.svg`;
  }

}
